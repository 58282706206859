// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'bootstrap'

require("./stylesheets/application")


var confirmModal = function(callback) {
	$("#confirmModal").modal();
  $("#confirmModalCancel").on("click", function(){
    callback(false);
    $("#confirmModal").modal('hide');
  });

  $("#confirmModalSuccess").on("click", function(){
    callback(true);
    $("confirmModal").modal('hide');
  });
};

$(document).on('click', 'a.submit-form', e => {
	e.preventDefault()
  confirmModal(function(confirm) {
    if(confirm) { $(e.target).parents('form').submit() }
  });
})

$(document).on('submit', 'form.add-people', e => {
	for (var input of $(e.target).find('input[type="text"]')) {
	  if (input.value.length  && !/^ *$/.test(input.value)) {
	  	return true
	  }
	}
	return false
})

$(document).on('click', 'span.clear-person-name', e => {
	$(e.target).siblings('input[type="text"]').val('')
})

$(document).on('click', 'td.check-diversity', e => {
	const $el = $(e.target)
	const checkBoxes = $el.find('.checkbox')
	const checked = !checkBoxes.prop("checked")
	$el.toggleClass('crossed', checked)
	checkBoxes.prop("checked", checked)
})
